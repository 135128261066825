.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.dropzone {
  box-shadow: 1px 1px 2px #abc;
  border: 2px dashed #c1c1c1;
  background-color: #f0f0f0;
}

.dropzone:hover {
  border: 2px dotted #c1c1c1;
  background-color: #dfdfdf;
}

.dropzone:hover > .textdrop {
  color: #c9cd29;
}

.textdrop {
  text-align: center;
  line-height: 100px;
  color: #b4b4b4;
  font-size: 20px;
}

.content {
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upload-icon {
  width: 50px;
  height: 50px;

  background-size: 100%;
  text-align: center;
  margin: 0 auto;
  padding-top: 30px;
}

.drop-message {
  text-align: center;
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
}

.drop-container {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  width: 800px;
  height: 200px;
  border: 4px dashed #4aa1f3;
}

.file-display-container {
  position: fixed;
  width: 805px;
}

.file-status-bar {
  width: 100%;
  vertical-align: top;
  margin-top: 10px;
  margin-bottom: 20px;
  position: relative;
  line-height: 50px;
  height: 50px;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background-size: 100%;
  position: absolute;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
